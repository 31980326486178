import { isEqualArrays } from '../helpers/sharedFunction';

export const TTS_VOICES = [
  {
    value: ['GOOGLE'],
    label: 'Google',
    showCheckbox: false,
    children: [
      {
        value: ['GOOGLE', 'NEURAL'],
        label: 'Neural',
        showCheckbox: false,
        children: [
          {
            value: ['GOOGLE', 'NEURAL', 'FEMALE'],
            label: 'Female',
          },
          {
            value: ['GOOGLE', 'NEURAL', 'FEMALE_A'],
            label: 'Female A',
          },
          {
            value: ['GOOGLE', 'NEURAL', 'MALE'],
            label: 'Male',
          },
          {
            value: ['GOOGLE', 'NEURAL', 'MALE_A'],
            label: 'Male A',
          },
        ],
      },
      {
        value: ['GOOGLE', 'STANDARD'],
        label: 'Standard',
        showCheckbox: false,
        children: [
          {
            value: ['GOOGLE', 'STANDARD', 'FEMALE'],
            label: 'Female',
          },
          {
            value: ['GOOGLE', 'STANDARD', 'MALE'],
            label: 'Male',
          },
        ],
      },
    ],
  },
  {
    value: ['TRANSLATED'],
    label: 'Translated',
    showCheckbox: false,
    children: [
      {
        value: ['TRANSLATED', 'STANDARD'],
        label: 'Standard',
        showCheckbox: false,
        children: [
          {
            value: ['TRANSLATED', 'STANDARD', 'MALE'],
            label: 'Male',
          },
        ],
      },
      {
        value: ['TRANSLATED', 'CLONING'],
        label: 'Cloning',
        showCheckbox: false,
        children: [
          {
            value: ['TRANSLATED', 'CLONING', 'FEMALE'],
            label: 'Female',
          },
        ],
      },
    ],
  },
  {
    value: ['AZURE'],
    label: 'Azure',
    showCheckbox: false,
    children: [
      {
        value: ['AZURE', 'NEURAL'],
        label: 'Neural',
        showCheckbox: false,
        children: [
          {
            value: ['AZURE', 'NEURAL', 'FEMALE'],
            label: 'Female',
          },
          {
            value: ['AZURE', 'NEURAL', 'MALE'],
            label: 'Male',
          },
        ],
      },
    ],
  },
];

export const extractNodesAtDepth = (array, depth, parentValue) => {
  const result = [];
  const extractRecursively = (arr, currentDepth) => {
    arr.forEach(({ value, label, children }) => {
      if (!parentValue && currentDepth === depth)
        result.push({
          value: Array.isArray(value) ? value[value.length - 1] : value,
          label,
        });
      else if (
        isEqualArrays(value, parentValue) &&
        currentDepth === depth - 1 &&
        children
      )
        children.forEach(({ value: childValue, label: childLabel }) => {
          result.push({
            value: Array.isArray(childValue)
              ? childValue[childValue.length - 1]
              : childValue,
            label: childLabel,
          });
        });
      else if (children) extractRecursively(children, currentDepth + 1);
    });
  };

  extractRecursively(array, 0);
  return result;
};

export const CUSTOM_RULES = {
  SPORT: 'SPORT',
  TECH: 'TECH',
  AUTOMOTIVE: 'AUTOMOTIVE',
};
