import React from 'react';
import {
  ViewButton,
  EditButton,
  ListenButton,
  SynthesizeButton,
  PublishButton,
  RescrapePodcastButton,
  ReprocessButton,
  InsightsButton,
  AnalysisButton,
  OpenUrlButton,
} from '../Common/DataTable/tableActionButtons';
import {
  getFilter,
  getLabel,
  getLabels,
} from '../Common/DataTable/tableHelpers';
import { convertCamelCaseString } from '../../helpers/sharedFunction';

const createTableColumns = (
  openViewModal,
  openEditModal,
  openPreviewPodcastModal,
  openSynthesizePodcastAlert,
  openPublishPodcastAlert,
  openRescrapePodcastAlert,
  openReprocessPodcastAlert,
  openInsightsModal,
  openPodcastAnalysisModal,
  isStaff,
  sites,
  clusters,
) => [
  {
    title: 'Listen',
    name: 'listen',
    width: 60,
    align: 'center',
    formatterComponent: ({ row }) => (
      <ul className="list-inline font-size-20 contact-links mb-0">
        {openPreviewPodcastModal && row.scraped === 'completed' && (
          <ListenButton row={row} onPress={openPreviewPodcastModal} />
        )}
      </ul>
    ),
    filter: 'disabled',
    sorting: 'disabled',
  },
  {
    title: 'Title',
    name: 'title',
    formatterComponent: ({ row }) =>
      row.scraped === 'deleted' ? '*** DELETED ***' : row.title || '',
  },
  {
    title: 'Site',
    name: 'siteId',
    width: 150,
    formatterComponent: ({ value }) => getLabel(value, sites, 'id', 'name'),
    filter: (value, filter) =>
      getFilter(getLabel(value, sites, 'id', 'name'), filter.value),
  },
  {
    title: 'Managed',
    name: 'scraped',
    width: 110,
    formatterComponent: ({ value }) =>
      value === 'completed' ? (
        <span className="badge badge-success font-size-12">
          {value.toUpperCase()}
        </span>
      ) : ['deleted', 'error'].includes(value) ? (
        <span className="badge badge-danger font-size-12">
          {value.toUpperCase()}
        </span>
      ) : (
        <span className="badge badge-warning font-size-12">
          {value.toUpperCase()}
        </span>
      ),
  },
  {
    title: 'Synthesized',
    name: 'synthesized',
    width: 130,
    formatterComponent: ({ value }) =>
      value === 'completed' ? (
        <span className="badge badge-success font-size-12">
          {value.toUpperCase()}
        </span>
      ) : value === 'error' ? (
        <span className="badge badge-danger font-size-12">
          {value.toUpperCase()}
        </span>
      ) : (
        <span className="badge badge-warning font-size-12">
          {value ? convertCamelCaseString(value).toUpperCase() : 'NEW'}
        </span>
      ),
  },
  {
    title: 'Published',
    name: 'published',
    width: 100,
    formatterComponent: ({ value }) =>
      value ? (
        <span className="badge badge-success font-size-12">YES</span>
      ) : (
        <span className="badge badge-danger font-size-12">NO</span>
      ),
    filter: (value, filter) => getFilter(value ? 'yes' : 'no', filter.value),
  },
  {
    title: 'Text Analysis',
    name: 'textAnalysis',
    width: 130,
    formatterComponent: ({ value }) =>
      value === 'completed' ? (
        <span className="badge badge-success font-size-12">
          {value.toUpperCase()}
        </span>
      ) : value === 'error' ? (
        <span className="badge badge-danger font-size-12">
          {value.toUpperCase()}
        </span>
      ) : (
        <span className="badge badge-warning font-size-12">
          {value ? convertCamelCaseString(value).toUpperCase() : 'NONE'}
        </span>
      ),
    hidden: true,
  },
  {
    title: 'Clusters',
    name: 'clusterIds',
    formatterComponent: ({ value }) => getLabels(value, clusters, 'id', 'name'),
    filter: (value, filter) =>
      getFilter(getLabel(value, sites, 'id', 'name'), filter.value),
    hidden: true,
  },
  {
    title: 'Error',
    name: 'error',
    width: 200,
    hidden: true,
  },
  {
    title: 'Action',
    name: 'action',
    width: isStaff ? 280 : 180,
    align: 'center',
    formatterComponent: ({ row }) => (
      <ul className="list-inline font-size-20 contact-links mb-0">
        {isStaff && <ViewButton row={row} onPress={openViewModal} />}
        {isStaff && <EditButton row={row} onPress={openEditModal} />}
        {openSynthesizePodcastAlert &&
          row.scraped === 'completed' &&
          (row.synthesized === 'new' || row.synthesized === 'atFirstPlay') && (
            <SynthesizeButton row={row} onPress={openSynthesizePodcastAlert} />
          )}
        {openPublishPodcastAlert &&
          row.scraped === 'completed' &&
          row.synthesized === 'completed' && (
            <PublishButton row={row} onPress={openPublishPodcastAlert} />
          )}
        {openRescrapePodcastAlert &&
          row.scraped === 'completed' &&
          !row.scrapeFromHTML && (
            <RescrapePodcastButton
              row={row}
              onPress={openRescrapePodcastAlert}
            />
          )}
        {openReprocessPodcastAlert &&
          (row.scraped === 'error' || row.synthesized === 'error') && (
            <ReprocessButton row={row} onPress={openReprocessPodcastAlert} />
          )}
        {openInsightsModal &&
          row.scraped === 'completed' &&
          row.synthesized === 'completed' && (
            <InsightsButton row={row} onPress={openInsightsModal} />
          )}
        {openPodcastAnalysisModal &&
          isStaff &&
          row.textAnalysis === 'completed' && (
            <AnalysisButton row={row} onPress={openPodcastAnalysisModal} />
          )}
        <OpenUrlButton row={row} />
      </ul>
    ),
    filter: 'disabled',
    sorting: 'disabled',
  },
];

export default createTableColumns;
