import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as dashboardActions from '../../store/dashboard/actions';
import { Col } from 'reactstrap';
import MiniCard from '../Common/MiniCard';
import { useTranslation } from 'react-i18next';

const TotalPodcastMetrics = (props) => {
  const insights = useSelector((state) => state.Dashboard.insights);

  const dispatch = useDispatch();

  useEffect(() => {
    //dispatch(dashboardActions.fetchInsights());
  }, []);

  const { t } = useTranslation();

  const totalPodcastMetrics = [
    {
      title: t('Total articles'),
      text: insights ? insights.totalPodcasts || 0 : 0,
      color: 'info',
      icon: 'bx bx-headphone',
      onUpdate: () => dispatch(dashboardActions.fetchInsights()),
    },
    {
      title: t('Total synthesized'),
      text: insights ? insights.totalSynthesized || 0 : 0,
      color: 'warning',
      icon: 'bx bx-headphone',
      onUpdate: () => dispatch(dashboardActions.fetchInsights()),
    },
    {
      title: t('Total published'),
      text: insights ? insights.totalPublished || 0 : 0,
      color: 'success',
      icon: 'mdi mdi-upload-outline',
      onUpdate: () => dispatch(dashboardActions.fetchInsights()),
    },
  ];

  return (
    <>
      {totalPodcastMetrics.map((metric, index) => (
        <Col key={'totalPodcastMetric_' + index} className="d-flex">
          <MiniCard {...metric} loading={props.loading} />
        </Col>
      ))}
    </>
  );
};

export default TotalPodcastMetrics;
