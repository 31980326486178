import React, { useState, useEffect } from 'react';
import { Container } from 'reactstrap';

import { useSelector, useDispatch } from 'react-redux';
import * as networksActions from '../../store/networks/actions';
import {
  isAdmin as isAdminSelector,
  isNetworker as isNetworkerSelector,
  isPublisher as isPublisherSelector,
  isAdvertiser as isAdvertiserSelector,
} from '../../selectors/auth';

// Pages Components
import SelectCountryAlert from '../../components/Dashboard/SelectCountryAlert';
import AdminDashboard from './admin';
import NetworkerDashboard from './networker';
import PublisherDashboard from './publisher';
import AdvertiserDashboard from './advertiser';

const Dashboard = () => {
  const isAdmin = useSelector(isAdminSelector);
  const isNetworker = useSelector(isNetworkerSelector);
  const isPublisher = useSelector(isPublisherSelector);
  const isAdvertiser = useSelector(isAdvertiserSelector);

  const countryId = useSelector((state) => state.Dashboard.countryId);
  const [countryAlert, setCountryAlert] = useState(!countryId);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(networksActions.syncNetworks());
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {!countryId ? (
            <SelectCountryAlert
              isOpen={countryAlert}
              onCancel={() => setCountryAlert(false)}
            />
          ) : (
            <>
              <>{isAdmin && <AdminDashboard />}</>
              <>{isNetworker && <NetworkerDashboard />}</>
              <>{isPublisher && <PublisherDashboard />}</>
              <>{isAdvertiser && !isPublisher && <AdvertiserDashboard />}</>
            </>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Dashboard;
