import React, { useState, useMemo } from 'react';
import {
  Container,
  Navbar,
  NavbarBrand,
  Nav,
  NavItem,
  Button,
} from 'reactstrap';
import Alert from '../../components/Common/Modal/Alert';

import * as podcastsActions from '../../store/podcasts/actions';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';

const BatchPodcastBar = ({ selectedPodcastIds }) => {
  const podcasts = useSelector((state) => state.Podcast.podcasts);
  const [rescrapePodcastAlert, setRescrapePodcastAlert] = useState(false);
  const [synthesizePodcastAlert, setSynthesizePodcastAlert] = useState(false);
  const [publishPodcastAlert, setPublishPodcastAlert] = useState(false);
  const [reprocessPodcastAlert, setReprocessPodcastAlert] = useState(false);
  const [deletePodcastAlert, setDeletePodcastAlert] = useState(false);

  const selectedPodcasts = useMemo(
    () => podcasts.filter(({ id }) => selectedPodcastIds.includes(id)),
    [podcasts, selectedPodcastIds],
  );

  const dispatch = useDispatch();

  const handleRescrapePodcasts = () => {
    const podcasts = selectedPodcasts.filter(
      (podcast) => !podcast.scrapeFromHTML,
    );
    dispatch(
      podcastsActions.updatePodcast(
        podcasts.map((podcast) => ({
          id: podcast.id,
          scraped: 'new',
        })),
      ),
    );
    setRescrapePodcastAlert(false);
  };

  const handleSynthesizePodcasts = () => {
    const podcasts = selectedPodcasts.filter(
      ({ scraped, synthesized }) =>
        scraped === 'completed' && synthesized === 'new',
    );
    dispatch(
      podcastsActions.updatePodcast(
        podcasts.map(({ id }) => ({
          id,
          synthesized: 'ready',
        })),
      ),
    );
    setSynthesizePodcastAlert(false);
  };

  const handlePublishPodcasts = () => {
    const podcasts = selectedPodcasts.filter(
      ({ scraped, synthesized, published }) =>
        scraped === 'completed' && synthesized === 'completed' && !published,
    );
    dispatch(
      podcastsActions.updatePodcast(
        podcasts.map(({ id }) => ({
          id,
          published: true,
          publishedAt: new Date(),
        })),
      ),
    );
    setPublishPodcastAlert(false);
  };

  const handleReprocessPodcasts = () => {
    const podcasts = selectedPodcasts.filter(
      ({ scraped, synthesized }) =>
        scraped === 'error' || synthesized === 'error',
    );
    dispatch(
      podcastsActions.updatePodcast(
        podcasts.map(({ id }) => ({
          id,
          error: '',
          scraped: 'new',
          synthesized: 'new',
        })),
      ),
    );
    setReprocessPodcastAlert(false);
  };

  const handleDeletePodcasts = () => {
    const podcasts = selectedPodcasts.filter(
      ({ scraped }) => scraped === 'completed',
    );
    dispatch(
      podcastsActions.updatePodcast(
        podcasts.map(({ id }) => ({
          id,
          scraped: 'delete',
        })),
      ),
    );
    setDeletePodcastAlert(false);
  };

  const { t } = useTranslation();

  return (
    <>
      <div
        className={`fixed-bottom ${
          selectedPodcasts.length ? 'visible' : 'invisible'
        }`}>
        <Navbar style={{ backgroundColor: '#2a3042' }} dark expand="md">
          <Container style={{ justifyContent: 'flex-start' }}>
            <NavbarBrand>{t('Manage')}:</NavbarBrand>
            <Nav pills>
              <NavItem style={{ marginRight: '15px' }}>
                <Button
                  color="info"
                  outline
                  className="waves-effect waves-light"
                  onClick={() => setRescrapePodcastAlert(true)}>
                  <i
                    className={`bx bx-repost font-size-20 align-middle mr-2`}></i>
                  {t('Rescrape Podcasts')}
                </Button>
              </NavItem>
              <NavItem style={{ marginRight: '15px' }}>
                <Button
                  color="warning"
                  outline
                  className="waves-effect waves-light"
                  onClick={() => setSynthesizePodcastAlert(true)}>
                  <i
                    className={`bx bx-headphone font-size-20 align-middle mr-2`}></i>
                  {t('Synthesize Podcasts')}
                </Button>
              </NavItem>
              <NavItem style={{ marginRight: '15px' }}>
                <Button
                  color="success"
                  outline
                  className="waves-effect waves-light"
                  onClick={() => setPublishPodcastAlert(true)}>
                  <i
                    className={`bx bx-show font-size-18 align-middle mr-2`}></i>
                  {t('Publish Podcasts')}
                </Button>
              </NavItem>
              <NavItem style={{ marginRight: '15px' }}>
                <Button
                  color="secondary"
                  outline
                  className="waves-effect waves-light"
                  onClick={() => setReprocessPodcastAlert(true)}>
                  <i
                    className={`bx bx-analyse font-size-20 align-middle mr-2`}></i>
                  {t('Reprocess Podcasts')}
                </Button>
              </NavItem>
              <NavItem style={{ marginRight: '15px' }}>
                <Button
                  color="danger"
                  outline
                  className="waves-effect waves-light"
                  onClick={() => setDeletePodcastAlert(true)}>
                  <i
                    className={`bx bx-trash font-size-20 align-middle mr-2`}></i>
                  {t('Delete Podcasts')}
                </Button>
              </NavItem>
            </Nav>
          </Container>
        </Navbar>
      </div>
      <Alert
        isOpen={rescrapePodcastAlert}
        title={`Do you want to rescrape ${selectedPodcasts.length} Podcasts?`}
        confirmBtnLabel="Rescrape"
        onCancel={() => setRescrapePodcastAlert(false)}
        onConfirm={handleRescrapePodcasts}>
        Keep attention!
      </Alert>
      <Alert
        isOpen={synthesizePodcastAlert}
        title={`Do you want to synthesize ${selectedPodcasts.length} Podcasts?`}
        confirmBtnLabel="Synthesize"
        onCancel={() => setSynthesizePodcastAlert(false)}
        onConfirm={handleSynthesizePodcasts}>
        Enjoy your podcast!
      </Alert>
      <Alert
        isOpen={publishPodcastAlert}
        title={`Do you want to publish ${selectedPodcasts.length} Podcasts?`}
        confirmBtnLabel="Publish"
        onCancel={() => setPublishPodcastAlert(false)}
        onConfirm={handlePublishPodcasts}>
        Keep attention!
      </Alert>
      <Alert
        isOpen={reprocessPodcastAlert}
        title={`Do you want to reprocess ${selectedPodcasts.length} Podcasts?`}
        confirmBtnLabel="Reprocess"
        onCancel={() => setReprocessPodcastAlert(false)}
        onConfirm={handleReprocessPodcasts}>
        There's no coming back!
      </Alert>
      <Alert
        isOpen={deletePodcastAlert}
        title={`Do you want to delete ${selectedPodcasts.length} Podcasts?`}
        confirmBtnLabel="Delete"
        onCancel={() => setDeletePodcastAlert(false)}
        onConfirm={handleDeletePodcasts}>
        There's no coming back!
      </Alert>
    </>
  );
};

export default BatchPodcastBar;
