import React, { useState } from 'react';
import { Row, Col, Card, CardBody, Collapse, Alert } from 'reactstrap';
import CSVUploadReader from '../Common/CSVReader';

import * as revenuesActions from '../../store/revenues/adUnits/actions';
import { useSelector, useDispatch } from 'react-redux';

import { useTranslation } from 'react-i18next';
import { Dots } from 'react-activity';
import moment from 'moment';

const START_DATE = '2024-08-01';
const CONFIG_CSV = {
  header: true,
  delimiter: ',',
  dynamicTyping: true,
  skipEmptyLines: true,
};

const DOWNLOAD_STATUS = {
  INITIAL: '',
  DOWNLOADING: 'downloading',
  COMPLETED: 'completed',
  ERROR: 'error',
};

const getMonths = (startDate) => {
  const starDate = moment(startDate);
  const endDate = moment();

  let date = starDate;
  let dates = [];

  while (date <= endDate) {
    const firstDay = date.startOf('month');
    dates.push({
      value: firstDay.format('YYYY-MM-DD'),
      label: firstDay.format('MMMM YYYY'),
    });
    date = firstDay.add(1, 'months');
  }

  return dates;
};

const ImportSpreakerRevenues = () => {
  const [month, setMonth] = useState(null);
  const [downloadStatus, setDownloadStatus] = useState(DOWNLOAD_STATUS.INITIAL);
  const [downloadError, setDownloadError] = useState('');

  const loading = useSelector((state) => state.Revenue.loading);
  const error = useSelector((state) => state.Revenue.error);
  const success = useSelector((state) => state.Revenue.success);

  const dispatch = useDispatch();

  const handleCSVData = ({ data, errors }) => {
    resetError();
    if (errors.length) {
      setDownloadStatus(DOWNLOAD_STATUS.ERROR);
      setDownloadError(`${errors[0].message} at row ${errors[0].row}`);
    }
    dispatch(revenuesActions.importSpeakerRevenues(data, month));
  };

  const resetError = () => {
    setDownloadError('');
    dispatch(revenuesActions.resetState());
  };

  const handleUploadRejected = () => {
    setDownloadStatus(DOWNLOAD_STATUS.ERROR);
    setDownloadError('Upload error');
  };

  const handleMonth = (month) => {
    setMonth(month === 'month' ? null : month);
  };

  const { t } = useTranslation();

  return (
    <Card>
      <CardBody>
        {loading ? (
          <Dots />
        ) : (
          <>
            {(downloadStatus === DOWNLOAD_STATUS.ERROR || error) && (
              <Alert color="danger">{downloadError || error}</Alert>
            )}
            {success && <Alert color="success">{success}</Alert>}
            <Row>
              <Col md="12">
                <p className="font-size-16 mb-4">
                  {t(
                    'Select the month for which you want to upload Spreaker revenues',
                  )}
                  .
                </p>
              </Col>
            </Row>
            <Row>
              <Col md="4">
                <div className="input-group input-group">
                  <select
                    className="custom-select custom-select"
                    defaultValue="month"
                    onChange={(e) => handleMonth(e.target.value)}>
                    <option key="month" value="month">
                      {t('Month')}
                    </option>
                    {getMonths(START_DATE).map(({ label, value }) => (
                      <option key={label} value={value}>
                        {label}
                      </option>
                    ))}
                  </select>
                  <div className="input-group-append">
                    <label className="input-group-text">{t('Month')}</label>
                  </div>
                </div>
              </Col>
            </Row>
            <Collapse isOpen={!!month}>
              <Row className="mt-4">
                <Col md="12">
                  <CSVUploadReader
                    config={CONFIG_CSV}
                    onUploadAccepted={handleCSVData}
                    onUploadRejected={handleUploadRejected}
                  />
                </Col>
              </Row>
            </Collapse>
          </>
        )}
      </CardBody>
    </Card>
  );
};

export default ImportSpreakerRevenues;
