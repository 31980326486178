import { put, all, call, takeLeading, select } from 'redux-saga/effects';
import types from './actionTypes';
import * as revenuesActions from './actions';
import firebase from 'firebase/app';
import rsf from '../../../helpers/firebase';
import { toDateFirebase, checkField } from '../../../helpers/sharedFunction';
import { MANDATORY_SPREAKER_CSV_IMPORT_FIELDS } from '../../../config/revenue';

const revenueTransformer = (revenue, data) => ({
  id: revenue.id,
  ...data,
  date: toDateFirebase(revenue, data, 'date').toDate(),
});

function* fetchRevenuesSaga({ startDate, endDate, filters }) {
  try {
    const countryId = yield select((state) => state.Dashboard.countryId);
    const revenuesRef = rsf.firestore.createCollectionRefWithFilters(
      'dailyAdUnitInsights',
      countryId,
      startDate,
      endDate,
      filters,
      null,
      null,
      'date',
    );

    const revenuesSnap = yield call(rsf.firestore.getCollection, revenuesRef);

    const revenues = revenuesSnap.docs.map((revenue) =>
      revenueTransformer(revenue, revenue.data()),
    );

    yield put(
      revenuesActions.fetchRevenuesSuccess(
        revenues,
        startDate,
        endDate,
        filters,
      ),
    );
  } catch (error) {
    yield put(revenuesActions.fetchRevenuesFailure(error));
  }
}

function* importSpreakerRevenuesSaga({ insights, month }) {
  try {
    //Validate insights
    insights = insights.map((insight, index) => {
      let column = '';
      const check = MANDATORY_SPREAKER_CSV_IMPORT_FIELDS.every(
        ({ name, type, required }) => {
          column = name;
          return checkField(insight[name], type, required);
        },
      );
      if (!check)
        throw new Error(
          `Check CSV at row ${index + 2}, column "${column}". Please retry!`,
        );

      insight = {
        day: insight.day,
        siteId: insight['podcast id'],
        impressions: insight.impressions,
        grossRevenue: insight['net revenue'] || 0,
      };

      return insight;
    });

    const generateSpreakerAdUnitInsightsFunction = firebase
      .functions()
      .httpsCallable(
        'generateSpreakerAdUnitInsights-generateSpreakerAdUnitInsights',
      );
    const { data } = yield call(generateSpreakerAdUnitInsightsFunction, {
      insights,
      month,
    });

    if (data.error) throw new Error(data.error.message);
    yield put(revenuesActions.importSpeakerRevenuesSuccess(data.message));
  } catch (error) {
    yield put(revenuesActions.importSpeakerRevenuesFailure(error));
  }
}

function* revenueSaga() {
  yield all([
    takeLeading(types.FETCH_AD_UNIT_REVENUES.REQUEST, fetchRevenuesSaga),
    takeLeading(
      types.IMPORT_SPREAKER_REVENUES.REQUEST,
      importSpreakerRevenuesSaga,
    ),
  ]);
}

export default revenueSaga;
